import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "tab1",
                href: "/tabs/dashboard"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.homeOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "tab2",
                href: "/tabs/recommendations"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.listOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "tab3",
                href: "/tabs/profile"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.personOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "tab4",
                onClick: _ctx.openMainMenu
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.ellipsisHorizontalOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}