import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { createI18n } from 'vue-i18n/dist/vue-i18n.esm-bundler.js';
import VueCookies from 'vue3-cookies'
import Popper from "vue3-popper";

import Vue3Tour from 'vue3-tour'
// import 'vue3-tour/dist/vue3-tour.css'
import './theme/vue3-tour-custom.css'

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/style.css';

/* Translations */
const messages = {
  en: {
    message: {
      hello: 'hello world'
    },
    menu: {
      profile_edit_link: 'Edit profile'
    },
    dashboard: {
      welcome: {
        text: 'Hey {name}, today is a good day to {textHighlight} two of your contacts.',
        text_highlight: 'connect'
      },
      invitations_headline: 'Your invitations',
      recent_activities_headline: 'Your Recent activities',
      recent_activities_button: 'Load more'
    },
    profile: {
      contact_headline: 'Contact',
      address_headline: 'Address',
      social_headline: 'Social profiles'
    },
    pitch: {
      benefit: {
        1: 'DSGVO-Konforme Übermittlung von Kontaktdaten',
        2: 'Behalte den Überblick über deine Empfehlungen und monetarisiere sie wenn gewünscht',
        3: 'Erweiter dein Netzwerk und schaffe Vertrauen durch Feedback und nachvollziehbare Empfehlungen'
      },
      button: 'Accept recommendation',
      new_teaser: {
        title: 'You are new on in-the-loop?',
        link: 'How it works!'
      },
      brought_to_you: {
        title: 'This recommendation ist brought to you by'
      },
      registration: {
        accept_conditions: 'I accept the terms and conditions of in-the-loop.',
        hint: {
          message: 'You will get an account and receive your login informations as e-mail. If you want to know more about our  privacy policy.',
          link: 'Click here'
        },
        already_registred: {
          title: 'Already in-the-loop?',
          link: 'Log in here'
        },
        addition_information: {
          title: 'Add additional informations'
        }
      }
    },
    login: {
      input_email: {
        label: 'E-Mail'
      },
      input_password: {
        label: 'Password'
      }
    },
  },
  de: {
    message: {
      hello: 'Hallo Welt'
    }
  }
}

/* Internalization */
const i18n = createI18n({
  locale: 'en', // set locale
  messages // set locale messages
})

const app = createApp(App)
  .use(IonicVue, {
    mode: 'ios',
  })
  .use(router)
  .use(i18n)
  .use(Vue3Tour)
  .use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
  })
  .component("Popper", Popper);

router.isReady().then(() => {
  app.mount('#app');
})