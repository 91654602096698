import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import FeedbackPage from '../views/FeedbackPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/dashboard'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/dashboard'
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/TabDashboard.vue')
      },
      {
        path: 'recommendations',
        name: 'RecommendationsList',
        component: () => import('@/views/RecommendationsList.vue')
      },
      {
        path: 'recommendations/detail/:id',
        name: 'RecommendationDetail',
        component: () => import('@/views/RecommendationDetail.vue')
      },
      {
        path: 'profile',
        name: 'UserProfile',
        component: () => import('@/views/UserProfile.vue')
      }
    ]
  },
  {
    path: '/feedback/',
    component: FeedbackPage,
    children: [
      {
        path: '',
        redirect: '/feedback/round-1/page-0'
      },
      {
        path: '/feedback/round-1/page-0',
        name: 'FeedbackRound1Page0',
        component: () => import('@/views/FeedbackRound1Page0.vue')
      },
      {
        path: '/feedback/round-1/page-1',
        name: 'FeedbackRound1Page1',
        component: () => import('@/views/FeedbackRound1Page1.vue')
      },
      {
        path: '/feedback/round-1/page-2',
        name: 'FeedbackRound1Page2',
        component: () => import('@/views/FeedbackRound1Page2.vue')
      },
      {
        path: '/feedback/round-1/page-3',
        name: 'FeedbackRound1Page3',
        component: () => import('@/views/FeedbackRound1Page3.vue')
      },
      {
        path: '/feedback/round-2/page-0',
        name: 'FeedbackRound2Page0',
        component: () => import('@/views/FeedbackRound2Page0.vue')
      },
      {
        path: '/feedback/round-2/page-1',
        name: 'FeedbackRound2Page1',
        component: () => import('@/views/FeedbackRound2Page1.vue')
      },
      {
        path: '/feedback/round-2/page-2',
        name: 'FeedbackRound2Page2',
        component: () => import('@/views/FeedbackRound2Page2.vue')
      },
      {
        path: '/feedback/round-2/page-3',
        name: 'FeedbackRound2Page3',
        component: () => import('@/views/FeedbackRound2Page3.vue')
      }
    ],
  },
  {
    path: '/pitch/:id',
    name: 'PitchPage',
    component: () => import('@/views/PitchPage.vue')
  },
  {
    path: '/pitch/:id/registration',
    name: 'PitchPageRegistration',
    component: () => import('@/views/PitchPageRegistration.vue')
  },
  {
    path: '/recommendations/detail/new',
    name: 'RecommendationDetailNew',
    component: () => import('@/views/RecommendationDetailNew.vue')
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/imprint',
    name: 'ImprintPage',
    component: () => import('@/views/ImprintPage.vue')
  },
  {
    path: '/terms-conditions',
    name: 'TermsAndConditionsPage',
    component: () => import('@/views/TermsAndConditionsPage.vue')
  },
  {
    path: '/privacy',
    name: 'PrivacyPage',
    component: () => import('@/views/PrivacyPage.vue')
  },
  {
    path: '/password',
    name: 'PasswordPage',
    component: () => import('@/views/PasswordPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
