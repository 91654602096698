
import { IonTabBar, IonTabButton, IonTabs, IonIcon, IonPage, IonRouterOutlet, menuController } from '@ionic/vue';
import { homeOutline, listOutline, personOutline, ellipsisHorizontalOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  methods: {
    openMainMenu() {
      menuController.enable(true, 'mainMenu');
      menuController.open('mainMenu');
    }
  },
  setup() {
    return {
      homeOutline,
      listOutline,
      personOutline,
      ellipsisHorizontalOutline
    }
  }
});
