
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import MainMenu from '@/components/MainMenu.vue';
import { defineComponent, ref, getCurrentInstance } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    MainMenu
  },
  methods: {
    //
  },
  setup() {
    //
  }
});
