
  import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonMenu,
    IonList,
    IonItem,
    IonLabel,
    IonAvatar,
    IonIcon,
    IonItemDivider,
    menuController,
    IonContent
  } from '@ionic/vue';
  import { addIcons } from 'ionicons';
  import {
    homeOutline,
    listOutline,
    personOutline,
    ellipsisHorizontalOutline,
    settingsOutline,
    radioOutline
  } from 'ionicons/icons';
  import { defineComponent } from 'vue';
  import userData from '@/data/active_user.json';

  addIcons({
    homeOutline,
    listOutline,
    personOutline,
    ellipsisHorizontalOutline,
    settingsOutline,
    radioOutline
  });

  export default defineComponent({
    name: 'MainMenu',
    components: {
      IonHeader,
      IonToolbar,
      IonTitle,
      IonMenu,
      IonList,
      IonItem,
      IonLabel,
      IonAvatar,
      IonIcon,
      IonItemDivider,
      IonContent
    },
    data(){
      return{
        user: userData
      }
    },
    methods: {
      //

      closeMenu() {
        menuController.enable(true, 'mainMenu');
        menuController.close('mainMenu');
      }
    },
    setup() {
      return {
        homeOutline,
        listOutline,
        personOutline,
        ellipsisHorizontalOutline,
        settingsOutline,
        radioOutline
      };
    }
  });
